import React, { useContext, useState, useEffect, useRef } from 'react';

import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { DescriptionOutlined, FiberManualRecord, KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { Backdrop, Grid, Tab, Tabs, Typography } from '@mui/material';
import KeyboardDoubleArrowRightTwoToneIcon from '@mui/icons-material/KeyboardDoubleArrowRightTwoTone';
import { Box } from '@mui/system';
import { some as lodashSome, isEmpty } from 'lodash';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { utils, writeFile } from 'xlsx';

import { setHistoricalToggle } from '../../actions/historicalView';
import CustomizedSwitches from '../atom/CustomizedSwitches';
import Dropdown from '../atom/Dropdown';
import HistoricalViewTile from '../atom/HistoricalViewTile';
import LineChartHistoricalView from '../atom/LineChartHistoricalView';
import AuthContext from '../context/AuthContext';
import FavoritesContext from '../context/FavoritesContext.js';
import FilterContext from '../context/FilterContext';
import Filters from '../molecules/Filters';
import HistoricalViewDashboardStyles from '../styles/HistoricalViewDashboardStyles';
import LayoutStyles from '../styles/LayoutStyles';
import ToolLinks from '../atom/ToolLinks';
import ExportDrawer from '../molecules/ExportDrawer';
import * as htmlToImage from 'html-to-image';

const HistoricalViewFavorites = (props) => {
  const { tableReports, personaId, showAlert } = props;
  const [defaultView, setdefaultView] = useState('Historical View');
  const { user } = useContext(AuthContext);
  const filterContext = useContext(FilterContext);
  const { setLocationType, locationTypeData, setSingleLocation, singleLocation } = filterContext;
  const {
    getHistoricalFav,
    activeTileHistoricalFav,
    setActiveTileHistoricalFav,
    activeTabHistoricalFav,
    setActiveTabHistoricalFav,
  } = useContext(FavoritesContext);
  const navigate = useNavigate();
  const [toggleRowStar, setToggleRowStar] = useState({});
  const [findFavourite, setFindFavourite] = useState({});
  const appInsights = useAppInsightsContext();
  const classes = HistoricalViewDashboardStyles();
  const layoutClasses = LayoutStyles();
  const graphref = useRef(null);
  const [activeTabPtd, setActiveTabPtd] = useState(0);
  const [activeGraph, setActiveGraph] = useState(false);
  const [kpiName, setKpiName] = useState(null);
  const [kpiUnit, setKpiUnit] = useState(null);
  const [dateUpdated, setDateupdated] = useState(null);
  const [periodWeek, setPeriodWeek] = useState(null);
  const [lineChartData, setLineChartData] = useState({
    time_series_week: null,
    time_series_period: null,
  });
  const [locationType, setLocationTypeData] = useState(null);
  const [lineChartWidth, setLineChartWidth] = useState({ widthAxis: 0, elementWidth: 0 });
  const [kpiToolLinks, setKpiToolLinks] = useState(null);
  const [activeToolLink, setActiveToolLink] = useState(false);
  const [ytdWeek, setYtdWeek] = useState(false);
  const [ytdPeriod, setYtdPeriod] = useState(false);
  const [timeSeriesWeekData, setTimeSeriesWeekData] = useState('');
  const [timeSeriesPeriodData, setTimeSeriesPeriodData] = useState('');
  const [location, setLocation] = useState('');
  const [count, setCount] = useState(1);
  const [countConstraint, setCountConstraint] = useState(null);
  const [rowId, setRowId] = useState(null);
  const [innerKpiId, setInnerKpiId] = useState('');

  // Local State for Export
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [tableReportsData, setTableReportsData] = useState([]);
  const [updateKpiExportIndex, setUpdateKpiExportIndex] = useState(null);
  const [startHoverExport, setStartHoverExport] = useState(null);
  const [exportFile, setExportFile] = useState(false);
  const [exportFileData, setExportFileData] = useState(null);
  const [exportStatus, setExportStatus] = useState(false);

  // This function to Set selected Tile Tool Links.
  const showToolLinks = (kpiToolLinks) => {
    setActiveToolLink(true);
    setKpiToolLinks(kpiToolLinks);
  };

  // Function for navigate to RSM Detail page
  const viewMore = () => {
    navigate(`/rsmHistoricalDetails/${rowId}`, { state: { favorite: true, innerKpiId: innerKpiId } });
  };

  const onSelect = (value) => {
    if (value.label === 'Daily View') {
      navigate('/favorites/dailyView');
    } else {
      navigate('/favorites/historicalView');
    }
  };
  // This function to display selected KPI graph.
  const callPBI = (id, name, time_series_week, time_series_period, unit, kpi_id, updatedAt) => {
    appInsights.trackEvent({
      name: 'Historical KPI Report',
      properties: {
        kpiName: name,
        personaName: user.persona.name,
        refUri: window.location.href,
      },
    });
    setRowId(id);
    setKpiName(name);
    setInnerKpiId(kpi_id);
    setKpiUnit(kpi_id === 'WAR001' ? '' : unit ?? '');
    let time_series_week_data = JSON.parse(time_series_week);
    let time_series_period_data = JSON.parse(time_series_period);
    if (isEmpty(time_series_week_data) || isEmpty(time_series_period_data)) {
      setPeriodWeek('');
      setDateupdated('');
      setTimeSeriesWeekData('');
      setTimeSeriesPeriodData('');
      setLineChartData({
        time_series_week: [],
        time_series_period: [],
      });
      setActiveGraph(false);
    } else {
      //Setting value to show the data last updated in filter
      let updated = time_series_week_data[time_series_week_data.length - 1].time_series.split('_');
      setPeriodWeek(`${updated[0]}${updated[1]}`.split(/['W', 'P']/));
      setDateupdated(updatedAt ? moment(updatedAt).format('MM/DD/YYYY') : '');
      setTimeSeriesWeekData(time_series_week);
      setTimeSeriesPeriodData(time_series_period);

      let currentYear = new Date().getFullYear();
      let newPeriodData = [];
      time_series_period_data.map((data) => {
        let splitData = data.time_series.split('_');
        data.time_series = `${splitData[0] % 1000} ${splitData[1]}`;
        if (ytdPeriod) {
          if (currentYear == +splitData[0]) {
            newPeriodData.push(data);
          }
        } else {
          newPeriodData.push(data);
        }
      });

      let newWeekData = [];
      time_series_week_data.map((data) => {
        let splitData = data.time_series.split('_');
        data.time_series = `${splitData[0] % 1000} ${splitData[1]}`;
        if (ytdWeek) {
          if (currentYear == +splitData[0]) {
            newWeekData.push(data);
          }
        } else {
          newWeekData.push(data);
        }
      });

      setCountConstraint(Math.ceil(newWeekData.length / 18));
      setLineChartData({
        time_series_week: SetGraphSlice(newWeekData, count),
        time_series_period: newPeriodData,
      });

      setActiveGraph(true);
    }
  };

  useEffect(() => {
    if (timeSeriesWeekData !== '') {
      let currentYear = new Date().getFullYear();
      let newWeekData = [];
      let time_series_week_data = JSON.parse(timeSeriesWeekData);
      time_series_week_data.map((data) => {
        let splitData = data.time_series.split('_');
        data.time_series = `${splitData[0] % 1000} ${splitData[1]}`;
        if (ytdWeek) {
          if (currentYear == +splitData[0]) {
            newWeekData.push(data);
          }
        } else {
          newWeekData.push(data);
        }
      });
      setCountConstraint(Math.ceil(newWeekData.length / 18));
      setLineChartData({ ...lineChartData, time_series_week: SetGraphSlice(newWeekData, count) });
    }
  }, [count, ytdWeek]);

  useEffect(() => {
    if (timeSeriesPeriodData !== '') {
      let currentYear = new Date().getFullYear();
      let newPeriodData = [];
      let time_series_period_data = JSON.parse(timeSeriesPeriodData);
      time_series_period_data.map((data) => {
        let splitData = data.time_series.split('_');
        data.time_series = `${splitData[0] % 1000} ${splitData[1]}`;
        if (ytdPeriod) {
          if (currentYear == +splitData[0]) {
            newPeriodData.push(data);
          }
        } else {
          newPeriodData.push(data);
        }
      });
      setLineChartData({ ...lineChartData, time_series_period: newPeriodData });
    }
  }, [ytdPeriod]);

  const ytdHandle = (event) => {
    setYtdWeek(event.target.checked);
    setCount(1);
  };
  const CountSetting = (value) => {
    value === 1 ? setCount(Math.min(count + value, countConstraint)) : setCount(Math.max(count + value, 1));
  };

  const SetGraphSlice = (data, count) => {
    if (data.length - 18 * count >= 0) {
      return data.slice(data.length - 18 * count, data.length - 18 * (count - 1));
    } else {
      return data.slice(0, 18);
    }
  };

  // Set graph scroll to start
  useEffect(() => {
    if (activeGraph && lineChartData.time_series_week.length > 0 && lineChartData.time_series_period.length > 0) {
      graphref.current.scrollLeft = 0;
      setLineChartWidth({ ...lineChartWidth, elementWidth: graphref.current ? graphref.current.offsetWidth : 0 });
    }
  }, [activeGraph, lineChartData]);

  // This function will call for get the width of graph axis difference.
  const getLineChartWidth = (width) => {
    setLineChartWidth({ ...lineChartWidth, widthAxis: width });
  };

  // This function will call when user click on tile.
  const onTileClick =
    (report, id, name, time_series_week, time_series_period, unit, kpi_id, kpiToolLinks, updatedAt) => (e) => {
      setActiveTileHistoricalFav(report);
      callPBI(id, name, time_series_week, time_series_period, unit, kpi_id, updatedAt);
      showToolLinks(kpiToolLinks);
      setCount(1);
    };

  // This function to activate the selected tile tabs.
  const handleChange = (e, tab) => {
    e.preventDefault();
    setActiveTileHistoricalFav(null);
    setActiveTabHistoricalFav(tab);
  };

  // This function to activate the selected graph tabs.
  const handleChangePtd = (e, tab) => {
    e.preventDefault();
    setActiveTabPtd(tab);
  };

  useEffect(() => {
    if (user.persona.layoutId === 1 && location) {
      getHistoricalFav(user, '', locationType, location);
    }
  }, [location, user]);

  useEffect(() => {
    if (user.persona.layoutId !== 1) {
      setLocationTypeData(locationTypeData ? locationTypeData : user.locationType);
    }
  }, [user]);

  // By default call the graph activate function first time when user landing on the page.
  useEffect(() => {
    if (startHoverExport === null) {
      if (!isEmpty(tableReports) && activeTabHistoricalFav >= 0 && activeTileHistoricalFav === null) {
        if (activeTabHistoricalFav === 0) {
          let reportNew = [];
          tableReports.map(
            (elem, key1) =>
              elem.name !== 'Summary' &&
              elem.data
                .filter((report) => toggleRowStar['table'][report.reportDetail.kpi_id])
                .map((reportnew, key) => reportNew.push(reportnew)),
          );
          let report = reportNew[0];
          if (report) {
            callPBI(
              report.id,
              report.kpiName,
              report.reportDetail.time_series_week,
              report.reportDetail.time_series_period,
              report.reportDetail.last_4_week_unit,
              report.reportDetail.kpi_id,
              report.reportDetail.updatedAt,
            );
            showToolLinks(report.kpiToolLinks);
          } else {
            setKpiName('');
            setActiveGraph(false);
            setKpiToolLinks(null);
            setActiveToolLink(false);
          }
        } else {
          let report = tableReports[activeTabHistoricalFav]?.data.filter(
            (report) => toggleRowStar['table'][report.reportDetail.kpi_id],
          )[0];
          if (report) {
            callPBI(
              report.id,
              report.kpiName,
              report.reportDetail.time_series_week,
              report.reportDetail.time_series_period,
              report.reportDetail.last_4_week_unit,
              report.reportDetail.kpi_id,
              report.reportDetail.updatedAt,
            );
            showToolLinks(report.kpiToolLinks);
          } else {
            setKpiName('');
            setActiveGraph(false);
            setKpiToolLinks(null);
            setActiveToolLink(false);
          }
        }
      } else if (activeTileHistoricalFav) {
        let report = activeTileHistoricalFav;
        callPBI(
          report.id,
          report.kpiName,
          report.reportDetail.time_series_week,
          report.reportDetail.time_series_period,
          report.reportDetail.last_4_week_unit,
          report.reportDetail.kpi_id,
          report.reportDetail.updatedAt,
        );
        showToolLinks(report.kpiToolLinks);
      } else {
        setKpiName('');
        setActiveGraph(false);
        setKpiToolLinks(null);
        setActiveToolLink(false);
      }
    }
  }, [activeTabHistoricalFav, tableReports, toggleRowStar, activeTileHistoricalFav]);

  // Fetch the historical views data when user change the value of location type.
  useEffect(() => {
    if (locationType) {
      getHistoricalFav(user, '', locationType, '');
    }
  }, [locationType, user]);

  useEffect(() => {
    setLocation(singleLocation == '' ? user.location : singleLocation);
  }, [singleLocation, user]);

  // Function for set location type to local state
  const getLocationType = (locationType) => {
    setLocationTypeData(locationType);
    setLocationType(locationType);
  };

  const scroll = (scrollOffset) => {
    graphref.current.scrollLeft += scrollOffset;
  };
  const onToggleRowStarClick = (dataType, id, value, kpiId) => async (e) => {
    e.preventDefault();
    let data = { ...toggleRowStar };
    data[dataType][kpiId] = !value;
    setToggleRowStar({ ...toggleRowStar, ...data });
    const respData = await setHistoricalToggle({
      userId: user.id,
      fav: !value,
      personaId: personaId,
      kpiId: kpiId,
    });
    if (respData.error) {
      data[dataType][kpiId] = value;
      setToggleRowStar({ ...toggleRowStar, ...data });
      showAlert({
        alertMessage: respData.message,
        alertType: 'error',
        fontSize: '1rem',
      });
    }
  };
  // Callback function for set favorite data
  useEffect(() => {
    let favData = {
      table: {},
    };
    tableReports.map((report) => {
      report.data.map((r) => {
        Object.assign(favData['table'], { [r.reportDetail.kpi_id]: !!r.fav });
      });
    });
    setToggleRowStar({ ...toggleRowStar, ...favData });
    setTableReportsData(tableReports);
  }, [tableReports]);

  // Callback function for dispaly the No favourites selected or No data available
  useEffect(() => {
    let findTableFav = lodashSome(toggleRowStar['table'], (v) => v === true);
    setFindFavourite({
      ...findFavourite,
      table: findTableFav,
      all: findTableFav,
    });
  }, [toggleRowStar]);

  // Function for set location to local state
  const getLocation = (location) => {
    setLocation(location);
    setSingleLocation(location);
  };
  // Start: Export Image for Both options
  const hoverTile = () => {
    setActiveTabHistoricalFav(exportFileData.functionalCategory.activeIndex);
    setStartHoverExport(true);
  };

  useEffect(() => {
    if (startHoverExport === true) {
      if (exportFileData.kpiList.length - 1 === updateKpiExportIndex) {
        let newArr = [...tableReportsData]; // copying the old datas array
        let report = newArr[exportFileData.functionalCategory.activeIndex]?.data[0];
        if (report) {
          callPBI(
            report.id,
            report.kpiName,
            report.reportDetail.time_series_week,
            report.reportDetail.time_series_period,
            report.reportDetail.last_4_week_unit,
            report.reportDetail.kpi_id,
            report.reportDetail.updatedAt,
          );
          showToolLinks(report.kpiToolLinks);
        }
        newArr.map((report) => {
          report.data.map((r) => {
            r['hover'] = false;
            r['hide'] = false;
          });
        });
        setTableReportsData(newArr);
        setStartHoverExport(null);
        setUpdateKpiExportIndex(null);
        setExportFile(false);
        setExportFileData(null);
      } else {
        setStartHoverExport(false);
        setUpdateKpiExportIndex(updateKpiExportIndex === null ? 0 : updateKpiExportIndex + 1);
        setExportFile(false);
      }
    }
  }, [startHoverExport]);

  useEffect(() => {
    if (updateKpiExportIndex !== null) {
      let newArr = [...tableReportsData]; // copying the old datas array
      let tabReport = newArr[exportFileData.functionalCategory.activeIndex];
      if (tabReport.name === 'Summary') {
        newArr.map((records) => {
          if (records.name !== 'Summary') {
            records.data.map((r) => {
              r['hover'] = r.reportDetail.kpi_id === exportFileData.kpiList[updateKpiExportIndex].value;
              r['hide'] = r.reportDetail.kpi_id !== exportFileData.kpiList[updateKpiExportIndex].value;
              if (r.reportDetail.kpi_id === exportFileData.kpiList[updateKpiExportIndex].value) {
                callPBI(
                  r.id,
                  r.kpiName,
                  r.reportDetail.time_series_week,
                  r.reportDetail.time_series_period,
                  r.reportDetail.last_4_week_unit,
                  r.reportDetail.kpi_id,
                  r.reportDetail.updatedAt,
                );
                showToolLinks(r.kpiToolLinks);
              }
            });
          }
        });
      } else {
        tabReport.data.map((r) => {
          r['hover'] = r.reportDetail.kpi_id === exportFileData.kpiList[updateKpiExportIndex].value;
          r['hide'] = r.reportDetail.kpi_id !== exportFileData.kpiList[updateKpiExportIndex].value;
          if (r.reportDetail.kpi_id === exportFileData.kpiList[updateKpiExportIndex].value) {
            callPBI(
              r.id,
              r.kpiName,
              r.reportDetail.time_series_week,
              r.reportDetail.time_series_period,
              r.reportDetail.last_4_week_unit,
              r.reportDetail.kpi_id,
              r.reportDetail.updatedAt,
            );
            showToolLinks(r.kpiToolLinks);
          }
        });
      }

      setTableReportsData(newArr);
      setExportFile(true);
    }
  }, [updateKpiExportIndex]);

  useEffect(() => {
    const asyncFn = async () => {
      if (exportFile === true) {
        await downloadImageUsingHtmlToImage('png');
      }
    };
    setTimeout(asyncFn, 1000);
    // asyncFn();
  }, [exportFile]);

  const downloadTheImage = (type, dataUrl) => {
    const a = document.createElement('a');
    a.href = dataUrl;
    a.download = `${kpiName}.${type}`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  // It returns a promise that resolves with a data URL to a PNG image of the element or an array of data URLs to PNG images of each child element if options.multiple is true.
  const convertHtmlToImageFromType = (type) => {
    if (type === 'png') {
      return htmlToImage.toPng(document.body, {
        cacheBust: true,
      });
    } else {
      return htmlToImage.toJpeg(document.body, {
        cacheBust: true,
      });
    }
  };

  const downloadImageUsingHtmlToImage = async (type) => {
    const dataUrl = await convertHtmlToImageFromType(type);
    downloadTheImage(type, dataUrl);
    hoverTile();
  };

  // End: Export Image for Both options

  // Export the KPI data into the Excel

  const tileExcelData = (kpiDetails, data) => {
    if (kpiDetails.reportDetail.kpi_id === data.value) {
      return [
        kpiDetails.kpiName,
        `${
          kpiDetails.reportDetail.target === null
            ? 'N/A'
            : `${kpiDetails.reportDetail.target}${kpiDetails.reportDetail.target_unit}`
        }`,
        `${
          kpiDetails.reportDetail.last_week
            ? `${kpiDetails.reportDetail.last_week}${kpiDetails.reportDetail.last_week_unit}`
            : '(Blank)'
        }`,
        `${
          kpiDetails.reportDetail.last_4_week
            ? `${kpiDetails.reportDetail.last_4_week}${kpiDetails.reportDetail.last_4_week_unit}`
            : '(Blank)'
        }`,
        `${
          kpiDetails.reportDetail.year_to_date
            ? `${kpiDetails.reportDetail.year_to_date}${kpiDetails.reportDetail.year_to_date_unit}`
            : '(Blank)'
        }`,
      ];
    }
  };
  const graphExcelData = (kpiDetails, data, wb) => {
    let ws_graph_data = [
      ['Persona Name: ', user.persona.name],
      ['Country: ', user.country],
      ['Region: ', user.region],
      ['Location: ', user.location],
      ['Location Type: ', locationType],
    ];
    ws_graph_data.push(['KPI Selected: ', kpiDetails.kpiName]);
    if (kpiDetails.reportDetail.kpi_id === data.value) {
      let time_series_week_data = JSON.parse(kpiDetails.reportDetail.time_series_week);
      let time_series_period_data = JSON.parse(kpiDetails.reportDetail.time_series_period);

      if (isEmpty(time_series_week_data) || isEmpty(time_series_period_data)) {
        ws_graph_data.push(['Data Last Updated: ', ``]);
      } else {
        let updated = time_series_week_data[time_series_week_data.length - 1].time_series.split('_');
        let periodWeek = `${updated[0]}${updated[1]}`.split(/['W', 'P']/);
        let updatedAt = kpiDetails.reportDetail.updatedAt;
        let dateUpdated = updatedAt ? moment(updatedAt).format('MM/DD/YYYY') : '';
        ws_graph_data.push([
          'Data Last Updated: ',
          `${dateUpdated} (through ${periodWeek ? `${periodWeek[0]} ${periodWeek[1]}x${periodWeek[2]}` : ''})`,
        ]);
      }

      ws_graph_data.push([]);
      ws_graph_data.push(['Graph Type: ', 'By Period']);
      let currentYear = new Date().getFullYear();
      time_series_period_data.map((data) => {
        let splitData = data.time_series.split('_');
        data.time_series = `${splitData[0] % 1000} ${splitData[1]}`;
        data.kpi_value = `${data.kpi_value}${kpiDetails.reportDetail.last_4_week_unit}`;
        if (ytdPeriod) {
          if (currentYear == +splitData[0]) {
            ws_graph_data.push([data.time_series, data.kpi_value]);
          }
        } else {
          ws_graph_data.push([data.time_series, data.kpi_value]);
        }
      });
      ws_graph_data.push([]);
      ws_graph_data.push(['Graph Type: ', 'By Week']);
      time_series_week_data.map((data) => {
        let splitData = data.time_series.split('_');
        data.time_series = `${splitData[0] % 1000} ${splitData[1]}`;
        data.kpi_value = `${data.kpi_value}${kpiDetails.reportDetail.last_4_week_unit}`;
        if (ytdWeek) {
          if (currentYear == +splitData[0]) {
            ws_graph_data.push([data.time_series, data.kpi_value]);
          }
        } else {
          ws_graph_data.push([data.time_series, data.kpi_value]);
        }
      });
      let ws = utils.aoa_to_sheet(ws_graph_data);
      let kpi_name = kpiDetails.kpiName.replace(/ /g, '_');
      utils.book_append_sheet(wb, ws, `Graph-${kpi_name}`);
    }
  };
  const exportKpiDetails = () => {
    let visualType = exportFileData.visualType;
    const wb = utils.book_new();
    if (visualType === 'tile' || visualType === 'both') {
      let ws_data = [
        ['Persona Name: ', user.persona.name],
        ['Country: ', user.country],
        ['Region: ', user.region],
        ['Location: ', user.location],
        ['Location Type: ', locationType],
        [
          'Data Last Updated: ',
          `${dateUpdated} (through ${periodWeek ? `${periodWeek[0]} ${periodWeek[1]}x${periodWeek[2]}` : ''})`,
        ],
      ];

      ws_data.push([]);
      ws_data.push(['KPI', 'Target', 'LW', 'L4W', 'YTD']);
      let tabReport = tableReportsData[exportFileData.functionalCategory.activeIndex];
      exportFileData.kpiList.map((data) => {
        if (tabReport.name === 'Summary') {
          tableReportsData.map((records) => {
            if (records.name !== 'Summary') {
              records.data.map((kpiDetails) => {
                let excelData = tileExcelData(kpiDetails, data);
                if (excelData) {
                  ws_data.push(excelData);
                }
              });
            }
          });
        } else {
          tableReportsData[exportFileData.functionalCategory.activeIndex]['data'].map((kpiDetails) => {
            let excelData = tileExcelData(kpiDetails, data);
            if (excelData) {
              ws_data.push(excelData);
            }
          });
        }
      });
      const ws = utils.aoa_to_sheet(ws_data);
      utils.book_append_sheet(wb, ws, 'Tiles');
    }
    if (visualType === 'graph' || visualType === 'both') {
      let tabReport = tableReportsData[exportFileData.functionalCategory.activeIndex];
      exportFileData.kpiList.map((data) => {
        if (tabReport.name === 'Summary') {
          tableReportsData.map((records) => {
            if (records.name !== 'Summary') {
              records.data.map((kpiDetails) => {
                graphExcelData(kpiDetails, data, wb);
              });
            }
          });
        } else {
          tableReportsData[exportFileData.functionalCategory.activeIndex]['data'].map((kpiDetails) => {
            graphExcelData(kpiDetails, data, wb);
          });
        }
      });
    }
    let fileName = '';
    if (visualType === 'tile') {
      fileName = 'Historical_View_Tiles_Report';
    } else if (visualType === 'graph') {
      fileName = 'Historical_View_Graphs_Report';
    } else if (visualType === 'both') {
      fileName = 'Historical_View_Details';
    }
    writeFile(wb, `${fileName}.xlsx`);
  };

  const onExportFile = (data) => {
    setDrawerVisible(false);
    setExportFileData(data);
    onHandleExport();
  };

  const onHandleExport = () => {
    setExportStatus(true);
  };

  useEffect(() => {
    if (exportStatus) {
      if (exportFileData.fileType === 'excel') {
        exportKpiDetails();
      } else {
        hoverTile();
      }
      setExportStatus(false);
    }
  }, [exportStatus]);

  return (
    <Grid container>
      <Grid item container className={layoutClasses.leftContainer}>
        {updateKpiExportIndex !== null && (
          <Backdrop
            sx={{ color: '#fff', backgroundColor: 'rgb(0 0 0 / 0%)', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
          />
        )}
        <div class='main-container'>
          {user.persona.layoutId === 1 ? (
            <Filters
              user={user}
              countryLabel={true}
              locationLabel={user.persona.layoutId !== 1 ? true : false}
              locationSingleDropDown={user.persona.layoutId === 1 ? true : false}
              historicalViewLastUpdated={true}
              periodWeek={periodWeek}
              dateUpdated={dateUpdated}
              getLocation={getLocation}
              startHoverExport={updateKpiExportIndex !== null}
              showAlert={showAlert}
            />
          ) : (
            <Filters
              user={user}
              countryLabel={true}
              locationTypeSingleDropDown={true}
              regionLabel={user.persona.layoutId === 2 ? false : true}
              regionSingleDropDown={user.persona.layoutId === 2 ? true : false}
              getLocationType={getLocationType}
              rsmDailyViewLocationTypeData={locationTypeData}
              historicalViewLastUpdated={true}
              periodWeek={periodWeek}
              dateUpdated={dateUpdated}
              startHoverExport={updateKpiExportIndex !== null}
            />
          )}
        </div>
      </Grid>
      <Grid
        item
        container
        className={`${user.persona.layoutId === 1 ? layoutClasses.rightContainer : layoutClasses.rsmRightContainer} ${
          user.persona.layoutId === 1
            ? layoutClasses.historicalRightContainer
            : layoutClasses.rsmHistoricalRightContainer
        }`}
      >
        <ExportDrawer
          visible={drawerVisible}
          onClose={() => setDrawerVisible(false)}
          onExportFile={onExportFile}
          tableReports={tableReportsData}
          favorite={true}
          toggleRowStar={toggleRowStar}
        />
        <div class='main-container' style={{ display: 'contents' }}>
          <Grid item xl={12} xs={12} md={12} container spacing={0} className={layoutClasses.favoriteDropdownContainer}>
            <Grid item xl={6} xs={6} md={6} className={classes.customerService}>
              <Typography variant='h5' component={'h3'} style={{ fontWeight: '600' }}>
                Customer Service
              </Typography>
              <Dropdown
                options={[
                  { label: 'Daily View', value: 'Daily View' },
                  { label: 'Historical View', value: 'Historical View' },
                ]}
                isOptionEqualToValue={(option, value) => option.value === value}
                getOptionLabel={(option) => (option.value ? option.value : defaultView)}
                value={defaultView}
                onChange={(event, value) => onSelect(value)}
                popperbottom={41}
                className={layoutClasses.favoriteDropdownList}
              />
            </Grid>
            <Grid item container spacing={0} xl={6} xs={6} md={6} className={classes.exportContainer}>
              <Grid
                item
                xl={2.2}
                xs={2.2}
                md={2.2}
                className={classes.exportButton}
                onClick={() => {
                  setDrawerVisible(true);
                }}
              >
                <Typography className={classes.descriptionIcon}>
                  <DescriptionOutlined fontSize='small' className={classes.iconStyling} />
                  Export File
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container spacing={0} xl={12} xs={12} md={12} className={classes.RsmDashboardContainer}>
            <Grid
              item
              className={`${classes.noFavData} ${findFavourite['all'] ? classes.favoritePage : ''}`}
              xl={12}
              xs={12}
              md={12}
            >
              No favorites selected
            </Grid>

            {/* Tile Code */}
            <Grid
              item
              xl={12}
              xs={12}
              md={12}
              className={`${classes.rsmTableStyle} ${!findFavourite['table'] ? classes.favoritePage : ''}`}
            >
              <Box>
                <Box sx={{ borderColor: 'divider', mb: 1 }}>
                  <Tabs
                    variant='scrollable'
                    value={activeTabHistoricalFav}
                    onChange={handleChange}
                    aria-label='basic tabs example'
                    className={classes.rsmTabs}
                  >
                    {tableReportsData.map((elem, index) => (
                      <Tab
                        style={{ margin: '0px 25px 0px 0px' }}
                        label={elem.name}
                        key={elem.id}
                        id={`tab-${index}`}
                        className={classes.tabLabel}
                      />
                    ))}
                  </Tabs>
                </Box>
                <Grid
                  item
                  container
                  className={`${classes.tilesStyle} ${classes.rsmTilesStyle}`}
                  xl={12}
                  xs={12}
                  md={12}
                >
                  {tableReportsData.map((elem, key) =>
                    elem.name === 'Summary'
                      ? tableReportsData.map(
                          (elem, key1) =>
                            elem.name !== 'Summary' &&
                            elem.data.map((report, index) => {
                              return (
                                <Grid
                                  key={index}
                                  item
                                  className={`${report.hover ? '' : classes.iframeContainer} ${
                                    classes.rsmIframeContainer
                                  } ${activeTabHistoricalFav === key ? classes.rsmDisplayTab : ''}  ${
                                    !toggleRowStar['table'][report.reportDetail.kpi_id] ? classes.favoritePage : ''
                                  } ${report.hover ? '' : report.kpiName === kpiName ? classes.tileActive : ''} ${
                                    report.hover ? classes.screenshot : ''
                                  } ${report.hide ? classes.iframeContainerHide : ''}`}
                                >
                                  <HistoricalViewTile
                                    key={index}
                                    kpiName={report.kpiName}
                                    reportDetail={report.reportDetail}
                                    ratingPoint={toggleRowStar['table'][report.reportDetail.kpi_id]}
                                    onTileClick={onTileClick(
                                      report,
                                      report.id,
                                      report.kpiName,
                                      report.reportDetail.time_series_week,
                                      report.reportDetail.time_series_period,
                                      report.reportDetail.last_4_week_unit,
                                      report.reportDetail.kpi_id,
                                      report.kpiToolLinks,
                                      report.reportDetail.updatedAt,
                                    )}
                                    onToggleRowStarClick={onToggleRowStarClick(
                                      'table',
                                      report.id,
                                      toggleRowStar['table'][report.reportDetail.kpi_id],
                                      report.reportDetail.kpi_id,
                                    )}
                                  />
                                </Grid>
                              );
                            }),
                        )
                      : elem.data.map((report, index) => {
                          return (
                            <Grid
                              key={index}
                              item
                              className={`${report.hover ? '' : classes.iframeContainer} ${
                                classes.rsmIframeContainer
                              } ${activeTabHistoricalFav === key ? classes.rsmDisplayTab : ''}  ${
                                !toggleRowStar['table'][report.reportDetail.kpi_id] ? classes.favoritePage : ''
                              } ${report.hover ? '' : report.kpiName === kpiName ? classes.tileActive : ''} ${
                                report.hover ? classes.screenshot : ''
                              } ${report.hide ? classes.iframeContainerHide : ''}`}
                            >
                              <HistoricalViewTile
                                key={index}
                                kpiName={report.kpiName}
                                reportDetail={report.reportDetail}
                                ratingPoint={toggleRowStar['table'][report.reportDetail.kpi_id]}
                                onTileClick={onTileClick(
                                  report,
                                  report.id,
                                  report.kpiName,
                                  report.reportDetail.time_series_week,
                                  report.reportDetail.time_series_period,
                                  report.reportDetail.last_4_week_unit,
                                  report.reportDetail.kpi_id,
                                  report.kpiToolLinks,
                                  report.reportDetail.updatedAt,
                                )}
                                onToggleRowStarClick={onToggleRowStarClick(
                                  'table',
                                  report.id,
                                  toggleRowStar['table'][report.reportDetail.kpi_id],
                                  report.reportDetail.kpi_id,
                                )}
                              />
                            </Grid>
                          );
                        }),
                  )}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </div>
        {activeGraph && lineChartData.time_series_week.length > 0 && lineChartData.time_series_period.length > 0 ? (
          <Grid item container xl={12} xs={12} md={12} className={`${classes.RsmPBIContainer}`}>
            <div class='main-container'>
              <Grid item xl={12} xs={12} md={12}>
                <Box xl={12} xs={12} md={12}>
                  <Tabs
                    variant='scrollable'
                    value={activeTabPtd}
                    onChange={handleChangePtd}
                    aria-label='basic tabs example'
                    className={classes.rsmTabs}
                  >
                    <Tab
                      style={{ padding: '0px 25px 0px 25px' }}
                      label={`By Week`}
                      key={`0`}
                      id={`tab-0`}
                      className={classes.tabLabel}
                    />
                    <Tab
                      style={{ padding: '0px 25px 0px 25px' }}
                      label={`By Period`}
                      key={`1`}
                      id={`tab-1`}
                      className={classes.tabLabel}
                    />
                  </Tabs>
                </Box>
              </Grid>
            </div>
            <Grid item xl={12} xs={12} md={12} className={`${classes.graphContainer}`}>
              <div class='main-container'>
                <div className={`${classes.graphLegendPagination}`}>
                  <Grid item xl={7} xs={7} md={7} className={`${classes.graphLegendContainer}`}>
                    <div className={`${classes.graphLegends}`}>
                      <FiberManualRecord fontSize='small' />
                      <span>{kpiName}</span>
                    </div>
                    {lineChartData.time_series_period[0]['national_avg'] && (
                      <div className={`${classes.graphLegends}`}>
                        <span>--- </span>
                        <span>National Average</span>
                      </div>
                    )}
                  </Grid>
                  {activeTabPtd === 0 ? (
                    <Grid item xl={5} xs={5} md={5} className={`${classes.graphLegendContainer}`}>
                      <div className={`${classes.graphLegends}`}>
                        <span>YTD</span>
                      </div>
                      <CustomizedSwitches key={`switch-ytd-week`} handleChange={ytdHandle} checked={ytdWeek} />
                      <KeyboardArrowLeft
                        onClick={() => CountSetting(1)}
                        className={count === countConstraint ? classes.graphscrollDisabled : classes.graphScrollButton}
                        fontSize='large'
                      />
                      <KeyboardArrowRight
                        onClick={() => CountSetting(-1)}
                        className={count === 1 ? classes.graphscrollDisabled : classes.graphScrollButton}
                        fontSize='large'
                      />
                    </Grid>
                  ) : (
                    <Grid item xl={5} xs={5} md={5} className={`${classes.graphLegendContainer}`}>
                      <div className={`${classes.graphLegends}`}>
                        <span>YTD</span>
                      </div>
                      <CustomizedSwitches
                        key={`switch-ytd-period`}
                        handleChange={(e) => setYtdPeriod(e.target.checked)}
                        checked={ytdPeriod}
                      />
                    </Grid>
                  )}
                </div>
              </div>

              <Box xl={12} xs={12} md={12} className={`${classes.powerBiWidget}`} ref={graphref}>
                {activeTabPtd === 0 ? (
                  <LineChartHistoricalView
                    lineChartData={lineChartData.time_series_week}
                    kpiUnit={kpiUnit}
                    activeTabHistoricalFav={activeTabPtd}
                    kpiName={kpiName}
                    getLineChartWidth={getLineChartWidth}
                  />
                ) : (
                  <LineChartHistoricalView
                    lineChartData={lineChartData.time_series_period}
                    kpiUnit={kpiUnit}
                    activeTabHistoricalFav={activeTabPtd}
                    kpiName={kpiName}
                    getLineChartWidth={getLineChartWidth}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        ) : (
          !isEmpty(tableReports) && (
            <Grid
              item
              container
              xl={12}
              xs={12}
              md={12}
              className={`${classes.noDataAvailable} ${classes.noDataAvailableGraph} ${
                findFavourite['all'] ? '' : classes.favoritePage
              }`}
            >
              <div class='main-container'>
                {kpiName ? `No graph data available for ${kpiName} KPI` : 'No data available'}
              </div>
            </Grid>
          )
        )}
        <div class='main-container' style={{ display: 'contents' }}>
          <Grid item xl={12} md={12} xs={12} container>
            {activeToolLink && (
              <Grid item xl={12} md={12} xs={12}>
                <ToolLinks kpiToolLinks={kpiToolLinks} />
              </Grid>
            )}
          </Grid>
          {activeGraph && user.persona.layoutId === 2 && (
            <Grid item xl={12} xs={12} md={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Grid
                item
                xl={4}
                xs={4}
                md={4}
                sx={{ display: 'flex', justifyContent: 'flex-end' }}
                className={classes.rankingsButton}
                onClick={viewMore}
              >
                <Typography className={classes.rankText}>
                  View Rankings
                  <KeyboardDoubleArrowRightTwoToneIcon />
                </Typography>
              </Grid>
            </Grid>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default HistoricalViewFavorites;
