import React, { useContext, useState, useEffect } from 'react';

import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { Grid, Tab, Tabs, Typography } from '@mui/material';
import KeyboardDoubleArrowRightTwoToneIcon from '@mui/icons-material/KeyboardDoubleArrowRightTwoTone';
import { Box } from '@mui/system';
import { some as lodashSome, isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';

import { setReportToggle } from '../../actions/home';
import config from '../../config';
import Dropdown from '../atom/Dropdown';
import PowerBiReport from '../atom/PowerBiReport';
import Tile from '../atom/Tile';
import TileBlock from '../atom/TileBlock';
import AuthContext from '../context/AuthContext';
import FavoritesContext from '../context/FavoritesContext';
import FilterContext from '../context/FilterContext';
import Filters from '../molecules/Filters';
import DashboardStyles from '../styles/DashboardStyles';
import LayoutStyles from '../styles/LayoutStyles';

const FavoritesRSMDashboard = (props) => {
  const { tableReports, showAlert, personaId, accessToken } = props;
  const { reportUrl, centralId, newCountry, newRegion, newLocationType, newLocation } = config;
  const filterContext = useContext(FilterContext);
  const { setLocationType, locationTypeData } = filterContext;
  const embedUrl = `${reportUrl}${centralId}`;
  const [changedFilterState, setChangedFilterState] = useState({
    location: [],
    country: [],
    region: [],
    locationType: [],
  });
  const { user, refreshTime } = useContext(AuthContext);
  const { getFavorites, activeTile, setActiveTile, activeTab, setActiveTab } = useContext(FavoritesContext);
  const appInsights = useAppInsightsContext();
  const classes = DashboardStyles();
  const layoutClasses = LayoutStyles();
  const [defaultView, setdefaultView] = useState('Daily View');
  const navigate = useNavigate();
  const [toggleRowStar, setToggleRowStar] = useState({});
  const [findFavourite, setFindFavourite] = useState({});
  const [tileId, setTileId] = useState(null);
  const [tilePageName, setTilePageName] = useState(null);
  const [modalEmbedUrl, setModalEmbedUrl] = useState(null);
  const [rowId, setRowId] = useState(null);
  const [kpiName, setKpiName] = useState(null);
  const [locationType, setLocationTypeData] = useState(null);
  const [innerKpiId, setInnerKpiId] = useState('');
  const [tableData, setTableData] = useState([]);
  // Function for navigate to RSM Detail page
  const viewMore = () => {
    navigate(`/rsmDetails/${rowId}`, { state: { favorite: true, innerKpiId: innerKpiId } });
  };
  const onSelect = (value) => {
    if (value.label === 'Daily View') {
      navigate('/favorites/dailyView');
    } else {
      navigate('/favorites/historicalView');
    }
  };

  // Call back function for set the table data based on active tab (created for custom tiles reorder)
  // Favorite summary data will be replaced with combination of all other tab data
  useEffect(() => {
    if (!isEmpty(tableReports) && activeTab >= 0) {
      if (activeTab == 0) {
        let tempTableData = [];
        tableReports.map((elem, key) => {
          if (elem.name !== 'Summary') {
            tempTableData.push(...elem.data);
          }
        });
        setTableData(tempTableData);
      } else {
        setTableData(tableReports[activeTab].data);
      }
    }
  }, [tableReports, activeTab]);

  // Function for dispaly the PBI garaph
  const callPBI = (id, name, linked, kpi_id) => {
    const { _id, graphPageName } = linked;
    let selectedLocationType = locationType ? locationType : user.locationType;
    let filters = '';
    if (selectedLocationType === 'ALL') {
      filters = `&filter=${newCountry}country%20eq%20'${user?.country.trim()}'%20and%20${newRegion}region%20eq%20'${user?.region.trim()}'`;
    } else if (selectedLocationType !== null) {
      filters = `&filter=${newCountry}country%20eq%20'${user?.country.trim()}'%20and%20${newRegion}region%20eq%20'${user?.region.trim()}'%20and%20${newLocationType}location_type_description%20eq%20'${selectedLocationType}'`;
    }
    if (filters) {
      appInsights.trackEvent({
        name: 'KPI Report',
        properties: {
          kpiName: name,
          personaName: user.persona.name,
          refUri: window.location.href,
        },
      });
      setRowId(id);
      setKpiName(name);
      setTileId(_id);
      setInnerKpiId(kpi_id);
      setTilePageName(graphPageName);
      setModalEmbedUrl(`${embedUrl}`);
      setChangedFilterState({
        location: [],
        country: [user.country.trim()],
        region: [user?.region.trim()],
        multipleLocationType: selectedLocationType === 'ALL' ? [] : [selectedLocationType],
      });
    }
  };

  // Function for tile click
  const onTileClick = (report, id, name, linked, kpi_id) => (e) => {
    setActiveTile(report);
    callPBI(id, name, linked, kpi_id);
  };
  // Function for click on favorite icon and call the api then display the favorite.
  const onToggleRowStarClick = (dataType, id, value, kpiId) => async (e) => {
    e.preventDefault();
    let data = { ...toggleRowStar };
    data[dataType][kpiId] = !value;
    setToggleRowStar({ ...toggleRowStar, ...data });
    const respData = await setReportToggle({
      userId: user.id,
      reportId: id,
      fav: !value,
      personaId: personaId,
      kpiId: kpiId,
    });
    if (respData.error) {
      data[dataType][kpiId] = value;
      setToggleRowStar({ ...toggleRowStar, ...data });
      showAlert({
        alertMessage: respData.message,
        alertType: 'error',
        fontSize: '1rem',
      });
    }
  };
  // Function for activate the tab for tiles
  const handleChange = (e, tab) => {
    e.preventDefault();
    setActiveTile(null);
    setActiveTab(tab);
  };

  // Callback function for call the api for tiles and tabular data
  useEffect(() => {
    setLocationTypeData(locationTypeData ? locationTypeData : user.locationType);
  }, [user]);

  // Callback function for set favorite data
  useEffect(() => {
    let favData = {
      table: {},
    };
    tableReports.map((report) => {
      report.data.map((r) => {
        Object.assign(favData['table'], { [r.reportDetail.kpi_id]: !!r.fav });
      });
    });
    setToggleRowStar({ ...toggleRowStar, ...favData });
  }, [tableReports]);

  // Callback function for dispaly the No favourites selected or No data available
  useEffect(() => {
    let findTableFav = lodashSome(toggleRowStar['table'], (v) => v === true);
    setFindFavourite({
      ...findFavourite,
      table: findTableFav,
      all: findTableFav,
    });
  }, [toggleRowStar]);

  // Display the tab data
  useEffect(() => {
    if (findFavourite && !isEmpty(tableReports) && activeTab >= 0 && activeTile === null) {
      let result = {};
      let report = {};
      if (activeTab === 0) {
        for (let i = 1; i < tableReports.length; i++) {
          report = tableReports[i]?.data;
          result = report.find((el) => toggleRowStar['table'][el.reportDetail.kpi_id]);
          if (result) {
            break;
          }
        }
      } else {
        report = tableReports[activeTab]?.data;
        result = report.find((el) => toggleRowStar['table'][el.reportDetail.kpi_id]);
      }

      if (result) {
        callPBI(result.id, result.kpiName, result.linked, result.reportDetail.kpi_id);
      } else {
        setModalEmbedUrl(null);
      }
    } else if (!isEmpty(tableReports) && activeTab >= 0 && activeTile === null) {
      let report = tableReports[activeTab]?.data[0];
      if (report) {
        callPBI(report.id, report.kpiName, report.linked);
      } else {
        setModalEmbedUrl(null);
      }
    } else if (activeTile) {
      let report = activeTile;
      callPBI(report.id, report.kpiName, report.linked, report.reportDetail.kpi_id);
    } else {
      setModalEmbedUrl(null);
    }
  }, [activeTab, findFavourite, tableReports, activeTile]);

  // Callback function for call the api for tiles and tabular data
  useEffect(() => {
    if (locationType) {
      getFavorites(user, locationType);
    }
  }, [locationType, user]);

  // Function for set location type to local state
  const getLocationType = (locationType) => {
    setLocationTypeData(locationType);
    setLocationType(locationType);
  };

  return (
    <Grid container>
      <Grid item container className={layoutClasses.leftContainer}>
        <Filters
          user={user}
          countryLabel={true}
          dailyViewRefreshTime={true}
          refreshTime={refreshTime}
          locationTypeSingleDropDown={true}
          regionLabel={false}
          regionSingleDropDown={true}
          getLocationType={getLocationType}
          rsmDailyViewLocationTypeData={locationTypeData}
          showAlert={showAlert}
        />
      </Grid>
      <Grid item container className={layoutClasses.rsmRightContainer}>
        <Grid item xl={12} xs={12} md={12} container spacing={0} className={layoutClasses.favoriteDropdownContainer}>
          <Grid item xl={6} xs={12} md={6} className={classes.customerService}>
            <Typography variant='h5' component={'h3'} style={{ fontWeight: '600' }}>
              Customer Service
            </Typography>
            <Dropdown
              options={[
                { label: 'Daily View', value: 'Daily View' },
                { label: 'Historical View', value: 'Historical View' },
              ]}
              isOptionEqualToValue={(option, value) => option.value === value}
              getOptionLabel={(option) => (option.value ? option.value : defaultView)}
              value={defaultView}
              onChange={(event, value) => onSelect(value)}
              popperbottom={41}
              className={layoutClasses.favoriteDropdownList}
            />
          </Grid>
        </Grid>

        <Grid item container spacing={0} xl={12} xs={12} md={12} className={classes.RsmDashboardContainer}>
          <Grid
            item
            className={`${classes.noFavData} ${findFavourite['all'] ? classes.favoritePage : ''}`}
            xl={12}
            xs={12}
            md={12}
          >
            No favorites selected
          </Grid>

          {/* Table Code */}
          <Grid
            item
            xl={12}
            xs={12}
            md={12}
            className={`${classes.rsmTableStyle} ${!findFavourite['table'] ? classes.favoritePage : ''}`}
          >
            <Box>
              <Box sx={{ borderColor: 'divider', mb: 1 }}>
                <Tabs
                  variant='scrollable'
                  // scrollButtons
                  // allowScrollButtonsMobile
                  value={activeTab}
                  onChange={handleChange}
                  aria-label='basic tabs example'
                  className={classes.rsmTabs}
                >
                  {tableReports.map((elem, index) => (
                    <Tab
                      style={{ margin: '0px 25px 0px 0px' }}
                      label={elem.name}
                      key={elem.id}
                      id={`tab-${index}`}
                      className={classes.tabLabel}
                    />
                  ))}
                </Tabs>
              </Box>
              <Grid item container className={`${classes.tilesStyle} ${classes.rsmTilesStyle}`} xl={12} xs={12} md={12}>
                {tableReports.map(
                  (elem, key) =>
                    !isEmpty(tableData) &&
                    tableData.map((report, index) => {
                      return (
                        <Grid
                          key={index}
                          item
                          className={`${classes.iframeContainer} ${classes.rsmIframeContainer} ${
                            activeTab === key ? classes.rsmDisplayTab : ''
                          } ${!toggleRowStar['table'][report.reportDetail.kpi_id] ? classes.favoritePage : ''} ${
                            report.kpiName === kpiName ? classes.tileActive : ''
                          } `}
                        >
                          {report.reportDetail.Design_Flag !== 2 ? (
                            <>
                              <Tile
                                key={index}
                                kpiName={report.kpiName}
                                reportDetail={report.reportDetail}
                                ratingPoint={toggleRowStar['table'][report.reportDetail.kpi_id]}
                                onToggleRowStarClick={onToggleRowStarClick(
                                  'table',
                                  report.id,
                                  toggleRowStar['table'][report.reportDetail.kpi_id],
                                  report.reportDetail.kpi_id,
                                )}
                                user={user}
                                KpiId={report.reportDetail.kpi_id}
                                onTileClick={onTileClick(
                                  report,
                                  report.id,
                                  report.kpiName,
                                  report.linked,
                                  report.reportDetail.kpi_id,
                                )}
                              />
                            </>
                          ) : (
                            <TileBlock
                              key={index}
                              kpiName={report.kpiName}
                              reportDetail={report.reportDetail}
                              ratingPoint={toggleRowStar['table'][report.reportDetail.kpi_id]}
                              onToggleRowStarClick={onToggleRowStarClick(
                                'table',
                                report.id,
                                toggleRowStar['table'][report.reportDetail.kpi_id],
                                report.reportDetail.kpi_id,
                              )}
                              user={user}
                              KpiId={report.reportDetail.kpi_id}
                              onTileClick={onTileClick(
                                report,
                                report.id,
                                report.kpiName,
                                report.linked,
                                report.reportDetail.kpi_id,
                              )}
                            />
                          )}
                        </Grid>
                      );
                    }),
                )}
              </Grid>
            </Box>
          </Grid>
        </Grid>
        {modalEmbedUrl && user.persona.layoutId == 2 && (
          <Grid item container xl={12} xs={12} md={12} className={`${classes.RsmPBIContainer}`}>
            <Grid item xl={12} xs={12} md={12}>
              <Typography variant='h6' className={classes.powerBIKPIName}>
                {kpiName}
              </Typography>
            </Grid>
            <Grid item xl={12} xs={12} md={12}>
              <Box
                xs={12}
                className={`${classes.powerBiWidget}`}
                style={{ height: innerKpiId === 'WAR008' ? '350px' : '260px' }}
              >
                <PowerBiReport
                  accessToken={accessToken}
                  id={tileId}
                  KpiId={innerKpiId}
                  embedUrl={modalEmbedUrl}
                  pageName={tilePageName}
                  country={changedFilterState.country}
                  region={changedFilterState.region}
                  location={changedFilterState.location}
                  multipleLocationType={changedFilterState.multipleLocationType}
                />
              </Box>
            </Grid>
            <Grid item xl={12} xs={12} md={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Grid
                item
                xl={4}
                xs={4}
                md={4}
                sx={{ display: 'flex', justifyContent: 'flex-end' }}
                className={classes.rankingsButton}
                onClick={viewMore}
              >
                <Typography className={classes.rankText}>
                  View Rankings
                  <KeyboardDoubleArrowRightTwoToneIcon />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

FavoritesRSMDashboard.propTypes = {};

export default FavoritesRSMDashboard;
