import React from 'react';

import { Grid } from '@mui/material';

import HistoricalHQTileStyle from '../styles/HistoricalHQTileStyle';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import RatingTag from './RatingTag';

const HistoricalHQTile = (props) => {
  const classes = HistoricalHQTileStyle();
  const { kpiName, reportDetail, onTileClick, ratingPoint, onToggleRowStarClick, dragKpi } = props;
  // Code to remove decimal point from  all values of  WAR004 kpi
  let reportDetailFiltered = { ...reportDetail };
  if (reportDetail.kpi_id === 'WAR004') {
    Object.keys(reportDetailFiltered).forEach((item) => {
      if (typeof reportDetailFiltered[item] == 'number') {
        reportDetailFiltered[item] = parseInt(reportDetailFiltered[item]);
      }
    });
  } else {
    Object.keys(reportDetailFiltered).forEach((item) => {
      if (typeof reportDetailFiltered[item] == 'number') {
        reportDetailFiltered[item] = reportDetailFiltered[item].toFixed(2);
      }
    });
  }
  return (
    <div class='mainTileContainer'>
      <Grid item container spacing={0} xl={12} xs={12} md={12}>
        <Grid item className={classes.overlayRating} onClick={onToggleRowStarClick}>
          <RatingTag ratingScale={1} ratingPoint={ratingPoint} />
        </Grid>
        <Grid item container spacing={0} xl={12} xs={12} md={12} onClick={onTileClick}>
          <Grid item container spacing={0} xl={12} xs={12} md={12} className={classes.tileContainer}>
            <Grid item container spacing={0} xl={12} xs={12} md={12} className={classes.firstRow}>
              <Grid item xl={11} xs={11} md={11} className={classes.kpiName}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {dragKpi && <DragIndicatorIcon></DragIndicatorIcon>}
                  <span>{kpiName}</span>
                </div>
              </Grid>
            </Grid>
            <Grid item container spacing={0} xl={12} xs={12} md={12} className={classes.secondRow}>
              <Grid item xl={12} xs={12} md={12} className={classes.last4Weeks}>
                <span className={classes.opacityTitle}>Last Week</span>
                <span className={classes.last4Hrs} style={{ color: reportDetailFiltered.last_week_color }}>
                  {reportDetailFiltered.last_week
                    ? `${reportDetailFiltered.last_week}${reportDetailFiltered.last_week_unit}`
                    : '(Blank)'}
                </span>
              </Grid>
            </Grid>
            <Grid item container spacing={0} xl={12} xs={12} md={12} className={classes.lastRow}>
              <Grid item xl={12} xs={12} md={12} className={classes.last4WeeksRow}>
                <span className={classes.opacityTitle}>Last 4 Weeks</span>
                <span className={classes.last24Hrs} style={{ color: reportDetailFiltered.last_4_week_color }}>
                  {reportDetailFiltered.last_4_week
                    ? `${reportDetailFiltered.last_4_week}${reportDetailFiltered.last_4_week_unit}`
                    : '(Blank)'}
                </span>
              </Grid>
            </Grid>

            <Grid item xl={12} xs={12} md={12} className={classes.last4WeeksRow}>
              <span className={classes.opacityTitle}>Year to Date</span>
              <span className={classes.last4Hrs} style={{ color: reportDetailFiltered.year_to_date_color }}>
                {reportDetailFiltered.year_to_date
                  ? `${reportDetailFiltered.year_to_date}${reportDetailFiltered.year_to_date_unit}`
                  : '(Blank)'}
              </span>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default HistoricalHQTile;
