import React, { useState, useReducer } from 'react';

import { getHistoricalView, getHistoricalViewDetails } from '../../actions/historicalView';

import HistoricalViewContext from './HistoricalViewContext';

// add the details for the Resource Library
const GET_HISTORICAL_VIEW = 'GET_HISTORICAL_VIEW';
const GET_HISTORICAL_VIEW_DETAILS = 'GET_HISTORICAL_VIEW_DETAILS';

const HistoricalViewReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_HISTORICAL_VIEW:
      return {
        ...state,
        historicalViewData: payload,
      };
    case GET_HISTORICAL_VIEW_DETAILS:
      return {
        ...state,
        historicalViewDetails: payload,
      };

    default:
      return state;
  }
};
const HistoricalViewStates = ({ children, showAlert }) => {
  const initialState = {
    historicalViewData: [],
    historicalViewDetails: [],
  };

  const [activeTile, setActiveTileState] = useState(null);
  const [activeTab, setActiveTabState] = useState(0);

  const setActiveTile = (tile) => {
    setActiveTileState(tile);
  };

  const setActiveTab = (tab) => {
    setActiveTabState(tab);
  };

  const [state, dispatch] = useReducer(HistoricalViewReducer, initialState);

  const getHistoricalTiles = async (resp, pageView, region, locationType, location) => {
    const respData = await getHistoricalView(resp.id, pageView, region, locationType, location);
    if (respData.error) {
      showAlert({
        alertMessage: respData.message,
        alertType: 'error',
      });
    }

    const newTableData = respData.data?.tabReports?.map((item) => {
      const newTableObj = item.data.map((value) => {
        return {
          id: value.id,
          kpiName: value.name,
          reportDetail: value?.historical_view_details[0],
          fav: value.fav,
          kpiToolLinks: value?.kpiToolLinks?.KPI_TOOL_LINKS,
          kpiOrder: value.persona_reports.kpiOrder,
        };
      });
      newTableObj.sort((a, b) => a.kpiOrder - b.kpiOrder);
      return {
        id: item.id,
        name: item.name,
        data: newTableObj,
      };
    });

    dispatch({
      type: GET_HISTORICAL_VIEW,
      payload: newTableData,
    });
  };

  const getHistoricalDetails = async (resp, tileId, region, locationTypeAll, multipleLocationType, view) => {
    const respData = await getHistoricalViewDetails(
      resp.id,
      tileId,
      region,
      locationTypeAll,
      multipleLocationType,
      view,
    );
    if (respData.error) {
      showAlert({
        alertMessage: respData.message,
        alertType: 'error',
      });
    }
    dispatch({
      type: GET_HISTORICAL_VIEW_DETAILS,
      payload: respData.data.historicalViewDetails,
    });
  };

  return (
    <HistoricalViewContext.Provider
      value={{
        historicalViewData: state.historicalViewData,
        historicalViewDetails: state.historicalViewDetails,
        getHistoricalTiles,
        getHistoricalDetails,
        activeTile,
        setActiveTile,
        activeTab,
        setActiveTab,
      }}
    >
      {children}
    </HistoricalViewContext.Provider>
  );
};

export default HistoricalViewStates;
