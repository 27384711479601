/*
This file creates a reducer function for User profile / Edit profile pages.
It will have initial state and User profile / Edit profile types used in the alerts page.
*/
import React, { useReducer } from 'react';

import { filter as lodashFilter, keyBy as lodashKeyBy } from 'lodash';

import { archiveAlert, getAlerts, updateAlerts } from '../../actions/alert';
import { getCustomTilesData, getReportDetailList, updateKPIOrderData } from '../../actions/home';
import { pingUser } from '../../actions/user';
import config from '../../config';

import AuthContext from './AuthContext';

// types
const GET_USER = 'GET_USER';
const GET_REPORTS = 'GET_REPORTS';
const GET_REPORT_DETAIL_LIST = 'GET_REPORT_DETAIL_LIST';
const GET_TABLE_REPORTS = 'GET_TABLE_REPORTS';
const GET_ACCESS_TOKEN = 'GET_ACCESS_TOKEN';
const GET_CURRENT_STATE = 'GET_CURRENT_STATE';
const SET_INIT = 'SET_INIT';
const GET_USER_PROFILE = 'GET_USER_PROFILE';
const GET_YESTERDAY_ALERTS = 'GET_YESTERDAY_ALERTS';
const GET_TODAY_ALERTS = 'GET_TODAY_ALERTS';
const GET_TODAY_COUNT_ALERTS = 'GET_TODAY_COUNT_ALERTS';
const RESOLVE_ALERT = 'RESOLVE_ALERT';
const RESOLVE_ALERT_MSG = 'RESOLVE_ALERT_MSG';
const ARCHIVE_ALERT = 'ARCHIVE_ALERT';
const GET_ARCHIVE_ALERTS = 'GET_ARCHIVE_ALERTS';
const ADD_ARCHIVE_ALERTS = 'ADD_ARCHIVE_ALERTS';
const REMOVE_ARCHIVE_ALERTS = 'REMOVE_ARCHIVE_ALERTS';
const REFRESH_TIME = 'REFRESH_TIME';
const GET_TOOL_LINKS = 'GET_TOOL_LINKS';

// Reducer
const AuthReducer = (state, action) => {
  const { payload, type } = action;
  switch (type) {
    case GET_USER:
      return {
        ...state,
        user: payload,
      };
    case SET_INIT:
      return {
        ...state,
        init: payload,
      };
    case GET_REPORTS:
      return {
        ...state,
        reportData: payload,
      };
    case GET_REPORT_DETAIL_LIST:
      return {
        ...state,
        reportDetailList: payload,
      };
    case GET_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: payload,
      };
    case GET_TABLE_REPORTS:
      return {
        ...state,
        tableReportData: payload,
      };
    case GET_CURRENT_STATE:
      return {
        ...state,
        currentPage: payload,
      };
    case GET_USER_PROFILE:
      return {
        ...state,
        userProfile: payload,
      };
    case GET_TODAY_ALERTS:
      return {
        ...state,
        todayAlerts: payload,
      };
    case GET_YESTERDAY_ALERTS:
      return {
        ...state,
        yesterdayAlerts: payload,
      };
    case GET_ARCHIVE_ALERTS:
      return {
        ...state,
        archiveData: payload,
      };
    case ADD_ARCHIVE_ALERTS:
      return {
        ...state,
        archiveData: payload,
      };
    case REMOVE_ARCHIVE_ALERTS:
      return {
        ...state,
        archiveData: payload,
      };
    case GET_TODAY_COUNT_ALERTS:
    case RESOLVE_ALERT:
    case ARCHIVE_ALERT:
      return {
        ...state,
        alertsCount: payload,
      };
    case RESOLVE_ALERT_MSG:
      return {
        ...state,
        resolveMsg: payload,
      };
    case REFRESH_TIME:
      return {
        ...state,
        refreshTime: payload,
      };
    case GET_TOOL_LINKS:
      return {
        ...state,
        kpiToolLinks: payload,
      };
    default:
      return state;
  }
};

const AuthState = ({ children, showAlert }) => {
  const { reportUrl, centralId, newCountry, newLocation, newRegion, newLocationType } = config;
  const embedUrl = `${reportUrl}${centralId}`;
  // Initial state
  const initialState = {
    user: null,
    init: false,
    accessToken: null,
    reportData: [],
    reportDetailList: [],
    tableReportData: [],
    currentPage: '',
    userProfile: {},
    todayAlerts: [],
    archiveData: [],
    yesterdayAlerts: [],
    alertsCount: 0,
    kpiToolLinks: null,
  };
  const [state, dispatch] = useReducer(AuthReducer, initialState);

  // Function to set all type of alerts
  const updatedAlertsSates = async (alertsData) => {
    const alertCounts = alertsData?.todayAlerts?.filter((data) => data.resolved_status === 'ACTIVE').length;
    dispatch({
      type: GET_TODAY_COUNT_ALERTS,
      payload: alertCounts,
    });
    dispatch({
      type: GET_TODAY_ALERTS,
      payload: alertsData.todayAlerts,
    });
    dispatch({
      type: GET_YESTERDAY_ALERTS,
      payload: alertsData.yesterdayAlerts,
    });
    dispatch({
      type: GET_ARCHIVE_ALERTS,
      payload: alertsData.archiveAlerts,
    });
  };

  // Function to get and set user details
  const getUserLogin = async (response) => {
    const resp = await pingUser(response.account);
    dispatch({
      type: GET_USER,
      payload: resp,
    });
    dispatch({
      type: GET_ACCESS_TOKEN,
      payload: response.accessToken,
    });

    const { family_name, given_name } = response.account.idToken;
    const userDta = {
      family_name,
      given_name,
    };
    getUserProfile(userDta);
    if (resp.personaId) {
      // getCustomTilesTable(resp);
      alertsCountUpdate(resp);
    }
  };

  // Function to update userProfile state for LayoutId = 1 personas
  const updateProfileState = (resp) => {
    dispatch({
      type: GET_USER,
      payload: resp,
    });
    if (resp.personaId) {
      alertsCountUpdate(resp);
    }
  };

  // Function to set value true when application loading
  const setInit = () => {
    dispatch({
      type: SET_INIT,
      payload: true,
    });
  };

  // Function to get the user details
  const getUserProfile = async (userData) => {
    dispatch({
      type: GET_USER_PROFILE,
      payload: userData,
    });
  };

  // Function to get and set dashboard report data as tiles and tabular format
  const getCustomTilesTable = async (resp, locationType, location) => {
    const respData = await getCustomTilesData(resp.id, locationType, location);
    if (respData.error) {
      showAlert({
        alertMessage: respData.message,
        alertType: 'error',
      });
    }

    let filters = '';
    if (resp.persona.layoutId === 1) {
      filters = `&filter=${newCountry}country%20eq%20'${resp?.country.trim()}'%20and%20${newLocation}locationdesc%20eq%20'${resp?.location.trim()}'`;
    } else {
      filters = `&filter=${newCountry}country%20eq%20'${resp?.country.trim()}'%20and%20${newLocation}locationdesc%20eq%20PERRY PLANT%20and%20${newLocationType}locationtype%20eq%20'${
        resp?.locationType
      }'`;
    }
    const newTiles = respData.data?.tileReports?.map((item) => {
      return {
        id: item.id,
        kpiName: item.name,
        reportDetail: item?.report_details[0],
        linked: {
          _id: centralId,
          embedUrl: `${embedUrl}`,
          pageName: item.linkedReportId,
        },
        fav: item.fav,
        kpiOrder: item.persona_reports.kpiOrder,
      };
    });
    newTiles.sort((a, b) => a.kpiOrder - b.kpiOrder);

    const newTableData = respData.data?.tabReports?.map((item) => {
      const newTableObj = item.data.map((value) => {
        return {
          id: value.id,
          kpiName: value.name,
          reportDetail: value?.report_details[0],
          linked: {
            _id: centralId,
            pageName: value.linkedReportId,
            embedUrl: `${embedUrl}`,
            graphPageName: value.reportId,
          },
          fav: value.fav,
          kpiOrder: value.persona_reports.kpiOrder,
        };
      });
      newTableObj.sort((a, b) => a.kpiOrder - b.kpiOrder);
      return {
        id: item.id,
        name: item.name,
        data: newTableObj,
      };
    });
    dispatch({
      type: GET_REPORTS,
      payload: newTiles,
    });
    dispatch({
      type: GET_TABLE_REPORTS,
      payload: newTableData,
    });
    dispatch({
      type: REFRESH_TIME,
      payload: respData.data?.refreshTime,
    });
  };

  // Function to get and set report details based on kpi
  const getReportDetail = async (userId, locationType, kpi_id, location, region) => {
    const respData = await getReportDetailList(userId, locationType, kpi_id, location, region);
    if (respData.error) {
      showAlert({
        alertMessage: respData.message,
        alertType: 'error',
      });
    }

    let kpiDetail = {};
    let reportList = [];
    respData.data?.reports?.map((item) => {
      kpiDetail = {
        id: item.id,
        kpiName: item.name,
        linked: {
          pageName: item.linkedReportId,
          graphPageName: item.reportId,
          cardPageName: item.cardReportId,
        },
      };
      item.report_details.map((reportDetailData) => {
        reportList.push(reportDetailData);
      });
    });
    dispatch({
      type: GET_REPORT_DETAIL_LIST,
      payload: { kpiDetail: kpiDetail, reportList: reportList },
    });
    dispatch({
      type: REFRESH_TIME,
      payload: respData.data?.refreshTime,
    });
    dispatch({
      type: GET_TOOL_LINKS,
      payload: respData.data?.kpiToolLinks?.KPI_TOOL_LINKS,
    });
  };

  const unSetReportDetail = () => {
    dispatch({
      type: GET_REPORT_DETAIL_LIST,
      payload: {},
    });
  };

  // Function to get and set alert data
  const resolveAlert = async (data, user, count) => {
    let value, updatedTodaysAlert, archiveCount, archiveAlertKeys, updatedArchiveAlert;
    switch (data.action) {
      case 'resolved':
        dispatch({
          type: RESOLVE_ALERT,
          payload: count - 1,
        });
        value = await updateAlerts({ user_id: user.id, id: data.id, action: data.action });
        if (value.statusCode === 201) {
          dispatch({
            type: RESOLVE_ALERT_MSG,
            payload: { message: value.message, id: value.data.id, conflict: 'yes' },
          });
          state.todayAlerts[state.todayAlerts.findIndex((ele) => ele.id === value.data.id)] = value.data;
          dispatch({
            type: GET_TODAY_ALERTS,
            payload: state.todayAlerts,
          });
          return;
        }
        dispatch({
          type: RESOLVE_ALERT_MSG,
          payload: { message: data.message, id: value.data.id, conflict: 'no' },
        });
        dispatch({
          type: RESOLVE_ALERT,
          payload: count - 1,
        });
        state.todayAlerts[state.todayAlerts.findIndex((ele) => ele.id === value.data.id)] = value.data;
        dispatch({
          type: GET_TODAY_ALERTS,
          payload: state.todayAlerts,
        });
        return;
      case 'unResolved':
        dispatch({
          type: RESOLVE_ALERT,
          payload: count + 1,
        });
        value = await updateAlerts({ user_id: user.id, id: data.id, action: data.action });
        state.todayAlerts[state.todayAlerts.findIndex((ele) => ele.id === value.data.id)] = value.data;
        dispatch({
          type: GET_TODAY_ALERTS,
          payload: state.todayAlerts,
        });
        return;
      case 'archive':
        value = await archiveAlert({
          userId: user.id,
          alertId: data.id,
          kpiId: data.kpi_id,
          action: data.action,
          location: user.location,
        });
        // Count
        archiveCount = lodashFilter(value.data, (o) => {
          if (o.resolved_status === 'ACTIVE') return o;
        }).length;
        if (archiveCount > 0) {
          dispatch({
            type: ARCHIVE_ALERT,
            payload: count - archiveCount,
          });
        }
        // Update Archive Alerts
        updatedArchiveAlert = [...state.archiveData, ...value.data];
        dispatch({
          type: ADD_ARCHIVE_ALERTS,
          payload: updatedArchiveAlert,
        });
        // Update Today Alerts
        archiveAlertKeys = lodashKeyBy(value.data, 'id');
        updatedTodaysAlert = lodashFilter(state.todayAlerts, (u) => {
          return archiveAlertKeys[u.id] === undefined;
        });
        dispatch({
          type: GET_TODAY_ALERTS,
          payload: updatedTodaysAlert,
        });
        return;
      case 'unArchive':
        value = await archiveAlert({
          userId: user.id,
          alertId: data.id,
          kpiId: data.kpi_id,
          action: data.action,
          location: user.location,
        });
        // Count
        archiveCount = lodashFilter(value.data, (o) => {
          if (o.resolved_status === 'ACTIVE') return o;
        }).length;
        if (archiveCount > 0) {
          dispatch({
            type: ARCHIVE_ALERT,
            payload: count + archiveCount,
          });
        }
        // Update Archive Alerts
        archiveAlertKeys = lodashKeyBy(value.data, 'id');
        updatedArchiveAlert = lodashFilter(state.archiveData, (u) => {
          return archiveAlertKeys[u.id] === undefined;
        });
        dispatch({
          type: REMOVE_ARCHIVE_ALERTS,
          payload: updatedArchiveAlert,
        });
        // Update Today Alerts
        updatedTodaysAlert = [...state.todayAlerts, ...value.data];
        dispatch({
          type: GET_TODAY_ALERTS,
          payload: updatedTodaysAlert,
        });
        return;
      default:
        return value;
    }
  };

  // Function to get and set alerts
  const alertsCountUpdate = async (user) => {
    const alertsData = await getAlerts(user.id);
    updatedAlertsSates(alertsData);
  };

  // Function to update custom/user KPI order
  const updateKPIOrder = async (view, tab, list, user) => {
    const data = await updateKPIOrderData(user.id, user.personaId, view, tab, list);
    return data;
  };

  return (
    <AuthContext.Provider
      value={{
        user: state.user,
        init: state.init,
        currentPage: state.currentPage,
        accessToken: state.accessToken,
        favOnly: state.favOnly,
        reportData: state.reportData,
        tableReportData: state.tableReportData,
        userProfile: state.userProfile,
        alertsCount: state.alertsCount,
        todayAlerts: state.todayAlerts,
        archiveData: state.archiveData,
        yesterdayAlerts: state.yesterdayAlerts,
        resolveMsg: state.resolveMsg,
        reportDetailList: state.reportDetailList,
        refreshTime: state.refreshTime,
        kpiToolLinks: state.kpiToolLinks,
        getUserLogin,
        setInit,
        resolveAlert,
        alertsCountUpdate,
        getCustomTilesTable,
        getReportDetail,
        unSetReportDetail,
        updateProfileState,
        updateKPIOrder,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthState;
