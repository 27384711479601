import { makeStyles } from '@mui/styles';
const TileStyle = makeStyles(() => ({
  tileContainer: {
    padding: '6px 14px',
  },
  firstRow: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 10,
  },
  kpiName: {
    fontSize: '1.2rem',
    textTransform: 'uppercase',
    display: 'flex',
  },
  rating: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  secondRow: {
    display: 'flex',
    alignItems: 'center',
    // marginTop: 18,
  },
  innerRow: {
    display: 'flex',
    alignItems: 'center',
    padding: 3,
  },
  last24Hrs: {
    fontWeight: 'bold',
    fontSize: '1.6rem',
    paddingTop: 18,
    paddingBottom: 18,
  },
  targetValue: {
    fontWeight: 'bold',
    marginLeft: 16,
  },
  thirdRow: {
    marginTop: 10,
  },
  diffrence: {
    fontWeight: 'bold',
  },
  fromDays: {
    marginLeft: 16,
    fontSize: '1.2rem',
  },
  daily: {
    position: 'absolute',
    right: 20,
    bottom: 10,
  },
  agChartWrapper: {
    position: 'absolute',
    display: 'block',
    width: '45%',
    height: '45%',
    top: '38px',
  },
  overlayRating: {
    position: 'absolute',
    top: 4,
    right: 6,
    height: 35,
    cursor: 'pointer',
  },
  kpiAttr: {
    width: '160',
    fontSize: '1.1rem',
  },
  kpiAttrValue: {
    alignContent: 'end',
    fontWeight: 'bold',
    fontSize: '1.1rem',
  },
  thirdRowChild: {
    marginLeft: 10,
  },
  refreshDate: {
    position: 'absolute',
    right: 20,
  },
}));

export default TileStyle;
