import React, { useContext, useState, useEffect, useRef } from 'react';
import {
  Grid,
  Tab,
  Tabs,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { Box } from '@mui/system';
import { isEmpty } from 'lodash';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';

import { setReportToggle } from '../../actions/home';
import Modal from '../atom/Modal';
import PowerBiReport from '../atom/PowerBiReport';
import Tabular from '../atom/Tabular';
import TabBlock from '../atom/TabBlock';
import Tile from '../atom/Tile';
import TileBlock from '../atom/TileBlock';

import AuthContext from '../context/AuthContext';

import LayoutStyles from '../styles/LayoutStyles';
import DashboardStyles from '../styles/DashboardStyles';
import Filters from '../molecules/Filters';
import FilterContext from '../context/FilterContext';

const Dashboard = (props) => {
  const { reports, tableReports, accessToken, showAlert, personaId } = props;
  const { user, getCustomTilesTable, refreshTime, updateKPIOrder } = useContext(AuthContext);
  const filterContext = useContext(FilterContext);
  const { setSingleLocation, singleLocation } = filterContext;
  const appInsights = useAppInsightsContext();

  const classes = DashboardStyles();
  const layoutClasses = LayoutStyles();

  const [toggleRowStar, setToggleRowStar] = useState({});
  const [activeTab, setActiveTab] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [tileId, setTileId] = useState(null);
  const [tilePageName, setTilePageName] = useState(null);
  const [modalEmbedUrl, setModalEmbedUrl] = useState(null);
  const [location, setLocation] = useState('');
  const [kpiId, setKpiId] = useState('');
  const [tableData, setTableData] = useState([]);
  const [tileReports, setTileReports] = useState([]);
  const [dragKpi, setDragKpi] = useState(false);
  const dragItem = useRef(null);
  const dragOverItem = useRef(null);
  const timeoutRef = useRef(null);

  // Function for display PBI graph into the modal
  const onTileClick = (linked, name, kpiId) => (e) => {
    e.preventDefault();
    setKpiId(kpiId);
    const { embedUrl, _id, pageName } = linked;
    setTileId(_id);
    setTilePageName(pageName);
    setModalEmbedUrl(embedUrl);
    setModalOpen(true);
    appInsights.trackEvent({
      name: 'KPI Report',
      properties: {
        kpiName: name,
        personaName: user.persona.name,
        refUri: window.location.href,
      },
    });
  };
  // Call back function for set the table data based on active tab (created for custom tiles reorder)
  useEffect(() => {
    if (!isEmpty(tableReports) && activeTab >= 0) {
      setTableData(tableReports[activeTab].data);
    }
  }, [tableReports, activeTab]);

  useEffect(() => {
    if (!isEmpty(reports)) {
      setTileReports(reports);
    }
  }, [reports]);

  // Function for close modal
  const handleClose = () => {
    setModalOpen(false);
  };

  // Function for click on favorite icon and call the api then display the favorite.
  const onToggleRowStarClick = (dataType, id, value, kpiId) => async (e) => {
    e.preventDefault();
    let data = { ...toggleRowStar };
    data['tile'][kpiId] = !value;
    data['table'][kpiId] = !value;
    setToggleRowStar({ ...toggleRowStar, ...data });

    const respData = await setReportToggle({
      userId: user.id,
      reportId: id,
      fav: !value,
      personaId: personaId,
      kpiId: kpiId,
    });
    if (respData.error) {
      data['tile'][kpiId] = value;
      data['table'][kpiId] = value;
      setToggleRowStar({ ...toggleRowStar, ...data });
      showAlert({
        alertMessage: respData.message,
        alertType: 'error',
        fontSize: '1rem',
      });
    }
  };

  // Function for activate the tab for tiles
  const handleChange = (e, tab) => {
    e.preventDefault();
    setActiveTab(tab);
  };

  useEffect(() => {
    setLocation(singleLocation == '' ? user.location : singleLocation);
  }, [singleLocation]);

  // Callback function for call the api for tiles and tabular data
  useEffect(() => {
    if (location) {
      getCustomTilesTable(user, '', location);
    }
  }, [location]);

  // Callback function for set favorite data
  useEffect(() => {
    let favData = {
      tile: {},
      table: {},
    };
    reports.map((report) => {
      Object.assign(favData['tile'], { [report.reportDetail.kpi_id]: !!report.fav });
    });
    tableReports.map((report) => {
      report.data.map((r) => {
        Object.assign(favData['table'], { [r.reportDetail.kpi_id]: !!r.fav });
      });
    });
    setToggleRowStar({ ...toggleRowStar, ...favData });
  }, [reports, tableReports]);

  // Function for set location to local state
  const getLocation = (location) => {
    setLocation(location);
    setSingleLocation(location);
  };

  // Function for reorder the custom tiles
  const handleDragSortTiles = (e, index) => {
    const list = [...tileReports];
    const item = list[dragItem.current];
    list.splice(dragItem.current, 1);
    list.splice(dragOverItem.current, 0, item);
    dragItem.current = dragOverItem.current;
    list.map((elem, index) => {
      return (elem.order = index + 1);
    });
    setTileReports(list);

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    // Set a new timeout to update KPI order and get custom tiles after 10 seconds
    timeoutRef.current = setTimeout(async () => {
      const data = await updateKPIOrder(1, 1, list, user);
      if (!data.error) {
        showAlert({
          alertMessage: data.message,
          alertType: 'success',
        });
      }
      if (data.error) {
        showAlert({
          alertMessage: data.message,
          alertType: 'error',
        });
      }
      getCustomTilesTable(user, '', location);
    }, 5000);
  };

  // Function for reorder the custom tabs
  const handleDragSortTabs = (e, index) => {
    const list = [...tableData];
    const item = list[dragItem.current];
    list.splice(dragItem.current, 1);
    list.splice(dragOverItem.current, 0, item);
    dragItem.current = dragOverItem.current;
    list.map((elem, index) => {
      return (elem.order = index + 1);
    });
    setTableData(list);

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    // Set a new timeout to update KPI order and get custom tiles after 10 seconds
    timeoutRef.current = setTimeout(async () => {
      const data = await updateKPIOrder(1, activeTab + 2, list, user);
      if (!data.error) {
        showAlert({
          alertMessage: data.message,
          alertType: 'success',
        });
      }
      if (data.error) {
        showAlert({
          alertMessage: data.message,
          alertType: 'error',
        });
      }
      getCustomTilesTable(user, '', location);
    }, 5000);
  };

  return (
    <Grid container>
      <Grid item container className={layoutClasses.leftContainer}>
        <Filters
          user={user}
          countryLabel={true}
          locationLabel={user.persona.layoutId !== 1 ? true : false}
          locationSingleDropDown={user.persona.layoutId === 1 ? true : false}
          dailyViewRefreshTime={true}
          refreshTime={refreshTime}
          getLocation={getLocation}
          showAlert={showAlert}
          showDragFilter={true}
          dragKpi={dragKpi}
          setDragKpi={setDragKpi}
        />
      </Grid>
      <Grid item container className={layoutClasses.rightContainer}>
        <Grid item xl={12} xs={12} md={12} className={classes.customerService}>
          <Typography variant='h5' component={'h3'} style={{ fontWeight: '600' }}>
            Customer Service
          </Typography>
        </Grid>

        <Grid item container spacing={0} xl={12} xs={12} md={12} className={classes.dashboardContainer}>
          {isEmpty(tableReports) && isEmpty(reports) && (
            <Grid item className={`${classes.noFavData}`} xl={12} xs={12} md={12}>
              No data available
            </Grid>
          )}
          {/* tiles code */}
          <Grid item className={classes.tilesStyle} xl={5} xs={5} md={5}>
            {!isEmpty(tileReports) &&
              tileReports.map((report, index) => {
                return (
                  <Grid
                    key={report.id}
                    item
                    className={`${classes.iframeContainer} `}
                    draggable={dragKpi}
                    onDragStart={dragKpi ? (e) => (dragItem.current = index) : undefined}
                    onDragEnter={dragKpi ? (e) => (dragOverItem.current = index) : undefined}
                    onDragEnd={dragKpi ? handleDragSortTiles : undefined}
                  >
                    {report.reportDetail.Design_Flag !== 2 ? (
                      <>
                        <Tile
                          key={`tile-${report.id}`}
                          kpiName={report.kpiName}
                          reportDetail={report.reportDetail}
                          ratingPoint={toggleRowStar['tile'][report.reportDetail.kpi_id]}
                          onToggleRowStarClick={onToggleRowStarClick(
                            'tile',
                            report.id,
                            toggleRowStar['tile'][report.reportDetail.kpi_id],
                            report.reportDetail.kpi_id,
                          )}
                          dragKpi={dragKpi}
                          user={user}
                          KpiId={report.reportDetail.kpi_id}
                          onTileClick={onTileClick(report.linked, report.kpiName, report.reportDetail.kpi_id)}
                        />
                      </>
                    ) : (
                      <TileBlock
                        key={`tileblock-${report.id}`}
                        kpiName={report.kpiName}
                        reportDetail={report.reportDetail}
                        ratingPoint={toggleRowStar['tile'][report.reportDetail.kpi_id]}
                        onToggleRowStarClick={onToggleRowStarClick(
                          'tile',
                          report.id,
                          toggleRowStar['tile'][(report.reportDetail.kpi_id, report.reportDetail.kpi_id)],
                          report.reportDetail.kpi_id,
                        )}
                        dragKpi={dragKpi}
                        user={user}
                        KpiId={report.reportDetail.kpi_id}
                        onTileClick={onTileClick(report.linked, report.kpiName, report.reportDetail.kpi_id)}
                      />
                    )}
                  </Grid>
                );
              })}
          </Grid>
          {/* Table Code */}
          <Grid item xl={7} xs={7} md={7} className={`${classes.tableStyle}`}>
            <Box>
              <Box sx={{ borderColor: 'divider' }}>
                <Tabs
                  value={activeTab}
                  onChange={handleChange}
                  aria-label='basic tabs example'
                  className={classes.tabs}
                >
                  {tableReports.map((elem, index) => (
                    <Tab
                      style={{ margin: '0px 25px 0px 0px' }}
                      label={elem.name}
                      key={elem.id}
                      id={`tab-${index}`}
                      className={classes.tabLabel}
                    />
                  ))}
                </Tabs>
              </Box>
              {tableReports.map((elem, index) => {
                return (
                  <Grid
                    key={elem.id}
                    className={`${classes.tabContainer} ${activeTab === index ? classes.displayTab : ''}`}
                  >
                    <TableContainer key={`container-${elem.id}`}>
                      <Table aria-label='table' key={`table-${elem.id}`}>
                        <TableHead style={{ height: '50px', overflow: 'hidden' }}>
                          <TableRow className={classes.tableRows}>
                            <TableCell align='left' className={`${classes.tableHeader} ${classes.firstHeading}`}>
                              METRICS
                            </TableCell>
                            <TableCell align='center' className={`${classes.tableHeader} ${classes.secondHeading}`}>
                              LAST 24 HRS
                            </TableCell>
                            <TableCell align='center' className={`${classes.tableHeader} ${classes.thirdHeading}`}>
                              WTD
                            </TableCell>
                            <TableCell
                              align='left'
                              className={`${classes.tableHeader} ${classes.fourthHeading}`}
                            ></TableCell>
                            <TableCell align='center' className={`${classes.tableHeader} ${classes.TrendHeading}`}>
                              TREND
                            </TableCell>
                            <TableCell
                              align='left'
                              className={`${classes.tableHeader} ${classes.ratingHeading}`}
                            ></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {!isEmpty(tableData) &&
                            tableData.map((report, index) => (
                              <TableRow
                                hover
                                className={`${classes.tableBodyRows}`}
                                draggable={dragKpi}
                                onDragStart={dragKpi ? (e) => (dragItem.current = index) : undefined}
                                onDragEnter={dragKpi ? (e) => (dragOverItem.current = index) : undefined}
                                onDragEnd={dragKpi ? handleDragSortTabs : undefined}
                              >
                                {report.reportDetail.Design_Flag !== 2 ? (
                                  <Tabular
                                    key={`tabular-${report.id}`}
                                    kpiName={report.kpiName}
                                    reportDetail={report.reportDetail}
                                    ratingPoint={toggleRowStar['table'][report.reportDetail.kpi_id]}
                                    report={report}
                                    dragKpi={dragKpi}
                                    toggleRowStar={toggleRowStar}
                                    onToggleRowStarClick={onToggleRowStarClick}
                                    onTileClick={onTileClick(report.linked, report.kpiName, report.reportDetail.kpi_id)}
                                  />
                                ) : (
                                  <TabBlock
                                    key={`tabblock-${report.id}`}
                                    kpiName={report.kpiName}
                                    reportDetail={report.reportDetail}
                                    ratingPoint={toggleRowStar['table'][report.reportDetail.kpi_id]}
                                    report={report}
                                    dragKpi={dragKpi}
                                    toggleRowStar={toggleRowStar}
                                    onToggleRowStarClick={onToggleRowStarClick}
                                    onTileClick={onTileClick(report.linked, report.kpiName, report.reportDetail.kpi_id)}
                                  />
                                )}
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                );
              })}
            </Box>
          </Grid>
        </Grid>
        <Modal open={modalOpen} handleClose={handleClose}>
          <Box className={classes.boxStyle}>
            <PowerBiReport
              accessToken={accessToken}
              id={tileId}
              KpiId={kpiId}
              embedUrl={modalEmbedUrl}
              pageName={tilePageName}
              country={[user.country.trim()]}
              location={[singleLocation == '' ? user.location : singleLocation]}
            />
          </Box>
        </Modal>
      </Grid>
    </Grid>
  );
};

Dashboard.propTypes = {};

export default Dashboard;
